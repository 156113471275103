var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-1"},[_c('h4',{staticClass:"mt-4 mb-0"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.egressprofile',"allows_crud":_vm.allows_crud}}),(_vm.egressProfile)?[(_vm.allows_crud)?[_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_egressprofile'),expression:"'mesh.change_egressprofile'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.egressprofile',
              false,
              'Perfil de Egreso'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.egressprofile',\n              false,\n              'Perfil de Egreso'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`modal-edit-egressprofile-${_vm.egressProfile.id}`)}}})]:_vm._e(),_c('b-modal',{attrs:{"id":`modal-edit-egressprofile-${_vm.egressProfile.id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
          'mesh.egressprofile',
          false,
          'Perfil de Egreso'
        )}`,"size":"xl"}},[_c('EgressProfileForm',{attrs:{"EgressProfile":_vm.egressProfile,"career_id":_vm.egressProfile.career,"show_selected_career":false,"show_delete_button":false},on:{"updated":function($event){return _vm.$bvModal.hide(`modal-edit-egressprofile-${_vm.egressProfile.id}`)}}})],1)]:_vm._e(),(
        (_vm.isClosedEgressProfile && _vm.allows_crud) ||
        (_vm.isClosedEgressProfile && _vm.egressProfile && _vm.egressProfile.is_closed)
      )?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `Cambiar el estado de la ${_vm.$getVisibleNames(
            'mesh.egressprofile',
            false,
            'Perfil de Egreso'
          )}`
        ),expression:"\n          `Cambiar el estado de la ${$getVisibleNames(\n            'mesh.egressprofile',\n            false,\n            'Perfil de Egreso'\n          )}`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"noprint",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.changeStateEgressProfile()}}},[(_vm.egressProfile && _vm.egressProfile.is_closed)?[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"lock","scale":"1"}})]:[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"unlock","scale":"1"}})]],2)]:_vm._e()],2),_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"ml-auto mr-0 noprint d-flex"},[_c('b-button',{staticClass:"mb-2 ml-3 noprint",attrs:{"size":"sm"},on:{"click":function($event){return _vm.redirectToMatterProgramByEgressProfileView(_vm.egress_profile_id)}}},[_c('b-icon',{attrs:{"icon":"box-arrow-up-right"}}),_vm._v(" EVALUACIONES")],1),_c('ContainerButtonsDownload',{staticClass:"ml-2",attrs:{"id_button":`col-display-1-${_vm.egress_profile_id}`,"tooltip_text":''},scopedSlots:_vm._u([{key:"popover-container-buttons",fn:function(){return [_c('div',[(
                  (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                  _vm.$debug_change_duoc
                )?_c('GenericButtonDownload',{class:`w-100 text-left`,attrs:{"text_button":`Formato Creación Plan y Asignatura`,"tooltip_text":``,"click_button":_vm.DownloadExcel,"disabled_button":_vm.disabled_button_excel,"icon_excel":true}}):_vm._e(),(
                  (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                  _vm.$debug_change_duoc
                )?_c('GenericButtonDownload',{class:`w-100 mt-1 text-left`,attrs:{"text_button":`Reporte de Valorización ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                )}`,"tooltip_text":``,"click_button":_vm.DownloadEgressProfileMatterExcel,"disabled_button":_vm.disabled_button_excel,"icon_excel":true}}):_vm._e(),_c('GenericButtonDownload',{class:`mt-1 w-100 text-left`,attrs:{"text_button":`Formato de salida de ${_vm.$getVisibleNames(
                  'mesh.egressprofile',
                  false,
                  'Perfil de Egreso'
                )}`,"tooltip_text":``,"click_button":_vm.DownloadDocument,"disabled_button":_vm.disabled_button_excel,"icon_word":true}})],1)]},proxy:true}])})],1)]],2),_c('EgressProfile',{attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud}}),(_vm.egress_profile_id)?_c('MeshCustomFab',{staticClass:"noprint",attrs:{"egress_profile_id":_vm.egress_profile_id,"show_egress_profile":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }